<template>
  <v-container
    fluid
    tag="section"
  >
    <v-dialog
      v-model="carga"
      persistent
      width="300"
    >
      <v-card
        color="primary"
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          CARGANDO INFORMACIÓN...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-form
      ref="formClave"
      lazy-validation
    >
      <base-material-card
        width="100%"
        color="primary"
        icon="mdi-lock"
        title="Cambio de contraseña"
      >
        <v-card-title>
          <v-row justify="center">
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="claveUsuario"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                prepend-icon="mdi-lock"
                label="Clave nueva"
                dense
                :rules="regla"
                persistent-hint
                @click:append="show = !show"
              />
              <v-btn
                small
                dark
                block
                color="green"
                @click="cambiarClave()"
              >
                Cambio de clave
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </base-material-card>
    </v-form>
  </v-container>
</template>

<script>

  import axios from 'axios'
  export default {
    data: () => ({
      items: [],
      claveUsuario: '',
      dataUsuario: '',
      carga: false,
      regla: [v => !!v || 'El campo es requerido'],
      show: false,
    }),
    created () {
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioOdajup'))
      if (!this.dataUsuario) {
        localStorage.removeItem('usuarioOdajup')
        this.$router.push('/')
      } else {
        this.validarToken()
      }
    },
    mounted () {
      this.$store.commit('SET_VALOR', false)
    },
    methods: {
      cerrarSesion () {
        var logOut = confirm('¿Desea cerrar sesión?')
        if (logOut === true) {
          localStorage.removeItem('usuarioOdajup')
          this.$router.push('/')
        }
      },
      validarToken () {
        const headers = { headers: { 'access-token': localStorage.getItem('tokenOdajup') } }
        axios
          .get(`${localStorage.getItem('urlApiOdajup')}v0/token`, headers)
          .then((r) => {
            if (r.data.message !== true) {
              localStorage.removeItem('usuarioOdajup')
              this.$router.push('/')
            } else {
              this.carga = false
            }
          })
          .catch((err) => {
            alert('Servicio no disponible' + err)
            this.carga = false
          })
      },
      cambiarClave () {
        console.log(this.dataUsuario)
        if (this.$refs.formClave.validate()) {
          const headers = { headers: { 'access-token': localStorage.getItem('tokenOdajup') } }
          const data = { clave: this.claveUsuario, usuario: this.dataUsuario.id_usuario }
          console.log(data)
          axios
            .put(`${localStorage.getItem('urlApiOdajup')}v0/clave`, data, headers)
            .then(r => {
              console.log(r)
              if (r.data.message === true) {
                alert('Contraseña cambiada correctamente')
                this.$router.push('/registro')
              } else {
                alert('No se pudo cambiar la clave')
              }
            })
            .catch(err => {
              alert(err)
            })
        }
      },
    },
  }
</script>
